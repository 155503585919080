let DashboardAPI;
const env = process.env.TARGET_ENV;
if (env === "development") {
  DashboardAPI = "https://portal.dev.2132qsr.com";
} else if (env === "production") {
  DashboardAPI = "https://portal.ev.pebble.tech";
} else if (env === "staging") {
  DashboardAPI = "https://portal.staging.2132qsr.com";
} else if (env === "local") {
  DashboardAPI = "http://localhost:3002";
}

const endpoints = {
  API: {
    delete: {
      postEmail: `https://portal.staging.2132qsr.com/delete-mobile-account-request`,
    },
  },
};

export default endpoints;
