import { FC, useEffect } from "react";
import styles from "./Container.module.scss";
import Logo from "../../assets/mypebble-logo-green.png";
import EmailForm from "../EmailForm/EmailForm";
import EmailSent from "../EmailSent/EmailSent";
import Confirmed from "../Confirmed/Confirmed";
import UhOh from "../UhOh/UhOh";
import { useGlobalContext } from "../../config/store";
import { useSearchParams } from "react-router-dom";

import Load from "../Load/Load";
import axios from "axios";

const Container: FC = () => {
  const { page, setPage } = useGlobalContext();
  const [searchParams] = useSearchParams();

  const userEmail = searchParams.get("email");

  useEffect(() => {
    if (userEmail !== null) {
      setPage("loading");
      const decodedEmail = decodeURIComponent(userEmail);
      axios
        .delete(
          "https://portal.staging.2132qsr.com/delete-mobile-account-confirm",
          {
            data: { email: decodedEmail },
          }
        )
        .then(() => {
          setPage("confirmed");
        })
        .catch(() => {
          setPage("error");
        });
    } else {
      setPage("home");
    }
  }, []);

  return (
    <div className={styles.container}>
      <section className={styles.imgBox}>
        <img className={styles.imgBox_img} src={Logo} alt="Logo" />
      </section>

      {page === "home" && <EmailForm />}
      {page === "EmailSent" && <EmailSent />}
      {page === "loading" && <Load />}
      {page === "confirmed" && <Confirmed />}
      {page === "error" && <UhOh />}
    </div>
  );
};

export default Container;
